export const round = (value, decimals = 2) => (
  Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
)

export const getImageUrl = (url, res = 'low') => {
  let dsiredRes
  if (!url) {
    return url
  }
  if (res === 'low') {
    dsiredRes = '_low_res.jpg'
  } else if (res === 'mid') {
    dsiredRes = '_mid_res.jpg'
  } else {
    dsiredRes = '.jpg'
  }
  return url.replace('.jpg', dsiredRes)
}
