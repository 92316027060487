import React from 'react'
import { NamespacesConsumer, withI18n } from 'react-i18next'
import { connect } from 'react-redux'
import style from './GameDetails.scss'
import Spinner from 'react-spinkit'
import { withRouter } from 'react-router-dom'
import { withApi } from 'services/ApiContext'
import noResultsImg from '/../resources/img/not-found.svg'
import { Markdown } from 'react-showdown'
import { getImageUrl } from '/services/Functions'
import classNames from 'classnames'
import excellentImg from '/../resources/img/ratings/excellent.png'
import recommendedImg from '/../resources/img/ratings/recommended.png'
import mehImg from '/../resources/img/ratings/meh.png'
import skipImg from '/../resources/img/ratings/skip.png'
import Products from 'components/products/Products'
import backButtonImg from '/../resources/img/back-button.svg'

class GameDetails extends React.Component {
  constructor({ api, i18n, history }) {
    super()
    this.api = api
    this.history = history
    this.state = {
      loading: true,
      loadingProducts: true,
      game: null,
      readMore: false
    }
    this.i18n = i18n
  }
  componentDidMount() {
    const { id } = this.props.match.params
    const run = async () => {
      try {
        const game = await this.api.getGame(id)
        this.setState({ game, loading: false })
      } catch (e) {
        console.log('Could not load game')
        this.setState({ loading: false })
      }
    }
    run()
  }

  render() {
    return (
      <NamespacesConsumer ns={['game', 'genres']}>
        {t => {
          if (this.state.loading) {
            return (
              <div className={style.preWrapper}>
                <div className={style.spinner} >
                  <Spinner name='ball-scale-multiple' fadeIn='quarter' />
                </div>
              </div>
            )
          }
          if (!this.state.loading && !this.state.game) {
            return (
              <div className={style.preWrapper} >
                <img src={noResultsImg} />
                <span>{t('GAME_DOES_NOT_EXIST')}</span>
              </div>
            )
          }
          const imgUrl = `${process.env.REVOLUEGAME_ASSETS}${this.state.game.mainImage}`
          var backgroundStyle = {
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255,1)) , url(' + getImageUrl(imgUrl, 'mid') + ')'
          }
          let platformIconUrls = []
          let mainImage
          let images = []
          if (this.state.game) {
            platformIconUrls = {}
            this.state.game.platforms.forEach(p => {
              platformIconUrls[p.icon.replace('.svg', '_dark.svg')] = true
            })
            platformIconUrls = Object.keys(platformIconUrls)
            if (this.state.game.images) {
              images = [...this.state.game.images]
              if (images.length > 0) {
                mainImage = images.shift()
              }
            }
          }

          const getDate = () => {
            if (this.state.game) {
              if (this.state.game.released) {
                return new Date(
                  this.state.game.released
                ).toLocaleDateString(
                  navigator.language ? navigator.language : 'en',
                  { year: 'numeric', month: 'short', day: 'numeric' }
                )
              } else {
                return t('TBA')
              }
            } else {
              return ''
            }
          }

          const getRating = () => {
            let text
            let img
            if (this.state.game) {
              const rating = this.state.game.rating
              if (rating >= 4) {
                text = t('RATING_EXCELLENT')
                img = excellentImg
              } else if (rating >= 3) {
                text = t('RATING_RECOMMENDED')
                img = recommendedImg
              } else if (rating >= 2) {
                text = t('RATING_MEH')
                img = mehImg
              } else if (rating > 0) {
                text = t('RATING_SKIP')
                img = skipImg
              } else {
                text = t('RATING_UNKNOWN')
              }
              return (
                <div className={style.rating}>
                  <span>{text}</span>
                  {img &&
                    <img className={style.rating} src={img} />
                  }
                </div>
              )
            }
          }

          const getPlatforms = () => {
            if (this.state.game.platforms.length === 0) {
              return '--'
            }
            return this.state.game.platforms.map(p => p.name).join(', ')
          }

          const getGenres = () => {
            if (this.state.game.genres.length === 0) {
              return '--'
            }
            return this.state.game.genres.map(g => t('genres:' + g.name)).join(', ')
          }

          const getStores = () => {
            const stores = [...this.state.game.stores]
            if (stores.length === 0) {
              return '--'
            }
            let lastStore
            if (stores.length > 0) {
              lastStore = stores.pop()
            }
            return (
              <React.Fragment>
                {stores.map(s => (
                  <a key={s.id} target="_blank" href={s.gameUrl ? s.gameUrl : `http://${s.url}`}>
                    {`${s.name}, `}
                  </a>
                ))}
                {lastStore &&
                  <a target="_blank" href={lastStore.gameUrl ? lastStore.gameUrl : `http://${lastStore.url}`}>
                    {lastStore.name}
                  </a>
                }
              </React.Fragment>
            )
          }

          const getDescription = () => {
            if (this.i18n.language === 'es') {
              return this.state.game.descriptionEs ? this.state.game.descriptionEs : ''
            } else {
              return this.state.game.description ? this.state.game.description : ''
            }
          }

          return (
            <React.Fragment>
              <div className={style.wrapper}>
                <div className={style.pageArt}>
                  <div className={style.art} style={backgroundStyle}>
                    <div className={style.content}>
                      {this.history.length > 2 &&
                        <div className={style.backButton} onClick={() => {
                          this.history.goBack()
                        }}>
                          <img src={backButtonImg} />
                        </div>
                      }
                      <div className={style.left}>
                        <div className={style.heading}>
                          <span>{getDate()}</span>
                          <div className={style.platformIconsWrapper}>
                            {platformIconUrls.map(i => (
                              <img key={i} src={`${process.env.REVOLUEGAME_ASSETS}${i}`} />
                            ))}
                          </div>
                        </div>
                        <h1>{t('TITLE', { name: this.state.game.name })}</h1>
                        {getRating()}
                        <div className={classNames(style.about, { [style.expanded]: this.state.readMore })}>
                          <Markdown markup={getDescription()} />
                        </div>
                        <div className={style.readMoreWrapper}>
                          {!this.state.readMore && getDescription().length > 500 &&
                            <span onClick={() => {
                              this.setState({ readMore: true })
                            }}>
                              {t('READ_MORE')}
                            </span>
                          }
                          {this.state.readMore && getDescription().length > 500 &&
                            <span onClick={() => {
                              this.setState({ readMore: false })
                            }}>
                              {t('SHOW_LESS')}
                            </span>
                          }
                        </div>
                        <div className={style.details}>
                          <div className={style.detail}>
                            <span>{t('PLATFORMS')}</span>
                            <span>{getPlatforms()}</span>
                          </div>
                          <div className={style.detail}>
                            <span>{t('GENRES')}</span>
                            <span>{getGenres()}</span>
                          </div>
                        </div>
                        <div className={style.stores}>
                          <span>{t('STORES')}</span>
                          {getStores()}
                        </div>
                      </div>
                      <div className={style.right}>
                        {mainImage &&
                          <img src={`${process.env.REVOLUEGAME_ASSETS}${getImageUrl(mainImage, 'mid')}`} />
                        }
                        <div className={style.screenshotsWrapper}>
                          {images.map(image => (
                            <img key={image} src={`${process.env.REVOLUEGAME_ASSETS}${getImageUrl(image)}`} />
                          ))}
                        </div>
                      </div>
                      <div className={style.productsWrapper}>
                        <Products game={this.state.game} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment >
          )
        }
        }
      </NamespacesConsumer >
    )
  }
}
export default connect(state => ({
  genres: state.genres, stores: state.stores
}))(withI18n()(withRouter(withApi(GameDetails))))
