import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import checkoutEN from '/../resources/locales/en/checkout.json'
import countriesEN from '/../resources/locales/en/countries.json'
import footerEN from '/../resources/locales/en/footer.json'
import homeEN from '/../resources/locales/en/home.json'
import paymentEN from '/../resources/locales/en/payment.json'
import productsEN from '/../resources/locales/en/products.json'
import termsCompanyEN from '/../resources/locales/en/terms.company.json'
import termsCookiesEN from '/../resources/locales/en/terms.cookies.json'
import termsLegalEN from '/../resources/locales/en/terms.legal.json'
import termsLicensesEN from '/../resources/locales/en/terms.licenses.json'
import termsPrivacyEN from '/../resources/locales/en/terms.privacy.json'
import gameEN from '/../resources/locales/en/game.json'
import genresEN from '/../resources/locales/en/genres.json'

import checkoutES from '/../resources/locales/es/checkout.json'
import countriesES from '/../resources/locales/es/countries.json'
import footerES from '/../resources/locales/es/footer.json'
import homeES from '/../resources/locales/es/home.json'
import paymentES from '/../resources/locales/es/payment.json'
import productsES from '/../resources/locales/es/products.json'
import termsCompanyES from '/../resources/locales/es/terms.company.json'
import termsCookiesES from '/../resources/locales/es/terms.cookies.json'
import termsLegalES from '/../resources/locales/es/terms.legal.json'
import termsLicensesES from '/../resources/locales/es/terms.licenses.json'
import termsPrivacyES from '/../resources/locales/es/terms.privacy.json'
import gameES from '/../resources/locales/es/game.json'
import genresES from '/../resources/locales/es/genres.json'

export const languages = [{ 'en': 'ENGLISH' }, { 'es': 'SPANISH' }]

i18n.use(LanguageDetector)
let options = {
  resources: {
    en: {
      checkout: checkoutEN,
      countries: countriesEN,
      footer: footerEN,
      home: homeEN,
      payment: paymentEN,
      products: productsEN,
      termsCompany: termsCompanyEN,
      termsCookies: termsCookiesEN,
      termsLegal: termsLegalEN,
      termsLicenses: termsLicensesEN,
      termsPrivacy: termsPrivacyEN,
      genres: genresEN,
      game: gameEN
    },
    es: {
      checkout: checkoutES,
      countries: countriesES,
      footer: footerES,
      home: homeES,
      payment: paymentES,
      products: productsES,
      termsCompany: termsCompanyES,
      termsCookies: termsCookiesES,
      termsLegal: termsLegalES,
      termsLicenses: termsLicensesES,
      termsPrivacy: termsPrivacyES,
      genres: genresES,
      game: gameES
    }
  },
  fallbackLng: 'en',
  interpolation: { escapeValue: false }
}
i18n.init(options)
export default i18n
