import React from 'react'
import { NamespacesConsumer } from 'react-i18next'

import style from './Style.scss'

export default () => (
  <NamespacesConsumer ns={['termsLicenses']}>
    {t => {
      return (
        <div className={style.wrapper}>
          <section>
            <h1>{t('title')} </h1>
            <h2>{t('graphics')}</h2>
            <p>
              <div><a href='https://www.pexels.com/photo/white-brick-wall-1092364/'>Photo by rawpixel.com from Pexels </a></div>
              <div><a href='https://www.freepik.com/free-photos-vectors/business'>Business vector created by freepik - www.freepik.com</a></div>
              <div>Icons made by <a href='https://www.flaticon.com/authors/eucalyp' title='Eucalyp'>Eucalyp</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
              <div>Icons made by <a href='https://www.flaticon.com/authors/hanan' title='Hanan'>Hanan</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
              <div>Icons made by <a href='https://www.flaticon.com/authors/simpleicon' title='SimpleIcon'>SimpleIcon</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
              <div>Icons made by <a href='https://www.flaticon.com/authors/situ-herrera' title='Situ Herrera'>Situ Herrera</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
              <div>Icons made by <a href='https://www.freepik.com/?__hstc=57440181.6f116b932f87c5d1914bc9648eaf2877.1559386125981.1559386125981.1559598916047.2&__hssc=57440181.15.1559598916047&__hsfp=2473210772' title='Freepik'>Freepik</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
              <div>Icons made by <a href='https://www.flaticon.com/authors/bogdan-rosu' title='Bogdan Rosu'>Bogdan Rosu</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
              <div>Icons made by <a href='https://www.flaticon.com/authors/kiranshastry' title='Kiranshastry'>Kiranshastry</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
              <div>Icons made by <a href='https://www.flaticon.com/authors/monkik' title='monkik'>monkik</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
              <div>Icons made by <a href='https://www.freepik.com/' title='Freepik'>Freepik</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
              <div>Icons made by <a href='https://www.flaticon.com/authors/google' title='Google'>Google</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
              <div><a href='https://www.freepik.com/free-photos-vectors/character'>Character vector created by freepik - www.freepik.com</a></div>
              <a href='https://www.freepik.com/free-photos-vectors/technology'>Technology vector created by freepik - www.freepik.com</a>
            </p>
          </section>
        </div>

      )
    }}
  </NamespacesConsumer>
)
