import { all, fork } from 'redux-saga/effects'
import currenciesSaga from 'sagas/currenciesSaga'
import configSaga from 'sagas/configSaga'
import productsSaga from 'sagas/productsSaga'
import revoluvipSaga from 'sagas/revoluvipSaga'
import orderSaga from 'sagas/orderSaga'
import paymentSaga from 'sagas/paymentSaga'
import genresSaga from 'sagas/genresSaga'
import storesSaga from 'sagas/storesSaga'

export default ({ api }) => function * root () {
  yield all([
    fork(currenciesSaga({ api })),
    fork(configSaga({ api })),
    fork(productsSaga({ api })),
    fork(revoluvipSaga({ api })),
    fork(orderSaga({ api })),
    fork(paymentSaga({ api })),
    fork(genresSaga({ api })),
    fork(storesSaga({ api }))
  ])
}
