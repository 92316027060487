export default ({ revolutilityBaseUrl, revoluegameBaseUrl, fetch }) => ({
  getCurrencies: () => new Promise((resolve, reject) => {
    fetch(
      `${revolutilityBaseUrl}/currencies`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getCategories: () => new Promise((resolve, reject) => {
    fetch(
      `${revolutilityBaseUrl}/categories`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getProducts: (references) => new Promise((resolve, reject) => {
    fetch(
      `${revolutilityBaseUrl}/products/references`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({ references })
      }

    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  applyRevoluvip: ({ email, password }) => new Promise((resolve, reject) => {
    const params = new URLSearchParams()
    params.append('email', email)
    params.append('password', password)
    fetch(
      `${revolutilityBaseUrl}/revoluvip?${params.toString()}`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  placeOrder: ({ receiveValue, productId, locale = 'en', fields = null, revoluvip = null }) => new Promise((resolve, reject) => {
    let filteredLocale
    if (locale.includes('es')) {
      filteredLocale = 'es'
    } else {
      filteredLocale = 'en'
    }
    const request = {
      locale: filteredLocale,
      receiveValue,
      productId,
      origin: 'revoluegame'
    }
    if (fields && Object.keys(fields).length > 0) {
      request.fields = Object.keys(fields).map(key => ({ name: key, value: fields[key] }))
    }
    if (revoluvip && Object.keys(revoluvip).length > 0) {
      request.revoluvip = revoluvip
    }
    fetch(
      `${revolutilityBaseUrl}/orders`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(request)
      }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),

  paymentStatus: ({ id }) => new Promise((resolve, reject) => {
    fetch(
      `${revolutilityBaseUrl}/payments/revolupay/${id}`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getConfig: () => new Promise((resolve, reject) => {
    fetch(
      `${revolutilityBaseUrl}/config`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),

  getGames: ({ filters = {}, sort, limit = 25, offset = 0 }) => new Promise((resolve, reject) => {
    const params = new URLSearchParams()
    Object.keys(filters).forEach(k => {
      if (filters[k]) {
        params.append(k, filters[k])
      }
    })
    if (sort) {
      params.append('sort', sort)
    }
    params.append('limit', limit)
    params.append('offset', offset)
    fetch(
      `${revoluegameBaseUrl}/games?${params.toString()}`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getGenres: () => new Promise((resolve, reject) => {
    fetch(
      `${revoluegameBaseUrl}/genres`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json.genres))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getStores: () => new Promise((resolve, reject) => {
    fetch(
      `${revoluegameBaseUrl}/stores`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json.stores))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getGame: (id) => new Promise((resolve, reject) => {
    fetch(
      `${revoluegameBaseUrl}/games/${id}`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),

  geoip: () => new Promise((resolve, reject) => {
    fetch(
      `https://geoip-db.com/json/`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  })

})
