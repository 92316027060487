import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import style from './Products.scss'
import { withApi } from 'services/ApiContext'
import CurrencyFormat from '../shared/CurrencyFormat'
import noResultsImg from '/../resources/img/not-found.svg'
import Spinner from 'react-spinkit'
import { round } from 'services/Functions'
import Select from 'react-select'

import ProductSelectionModal from './ProductSelectionModal'

class Products extends React.Component {
  constructor ({ api, game, history }) {
    super()
    this.api = api
    this.game = game
    this.history = history
    this.state = {
      geoip: null,
      selectedProduct: null,
      selectedStores: [],
      selectedCountry: null,
      shouldShowProductModal: false,
      loading: true,
      gameProducts: [],
      storeProducts: []
    }
  }

  componentDidMount () {
    const run = async () => {
      let gameProducts = []
      let storeProducts = []
      try {
        gameProducts = (await this.api.getProducts(this.game.productIds)).products
        let storeProductIds = []
        this.game.stores.forEach(s => {
          storeProductIds = [...storeProductIds, ...s.productIds]
        })
        storeProducts = (await this.api.getProducts(storeProductIds)).products
      } catch (e) {
        console.log(e)
      }
      this.setState({
        loading: false,
        gameProducts,
        storeProducts,
        selectedStores: []
      })
      try {
        const geoipIso2 = (await this.api.geoip()).country_code
        this.setState({ geoip: geoipIso2 })
      } catch (e) {
        console.error(e)
      }
    }
    run()
  }

  selectProduct = (event, product) => {
    this.setState({ selectedProduct: product, shouldShowProductModal: true })
  }

  selectStore = (stores, action) => {
    let selectedStores = this.state.selectedStores
    if (action.action === 'remove-value') {
      selectedStores = selectedStores.filter(it => it.value !== action.removedValue.value)
    } else {
      selectedStores = stores
    }
    this.setState({ selectedStores })
  }

  selectCountry = country => {
    this.setState({ selectedCountry: country })
  }

  render () {
    return (
      <NamespacesConsumer ns={['products']}>
        {t => {
          const getDescription = (p) => {
            let description = p.product.description
            if (!description) {
              return ''
            }
            let name = p.product.name
            if (description.includes(name)) {
              return description.replace(name + ' - ', '')
            }
            description = description.trim()
            if (description.charAt(0) === '-') {
              return description.replace('- ', '')
            }
            return description
          }
          const getProducts = (products) => (
            <div className={style.productsContainer}>
              {products.map(p => (
                <div onClick={(e) => this.selectProduct(e, p)} className={style.product} key={p.product.id}>
                  <img className={style.logo} src={`${process.env.REVOLUTILITY_ASSETS}/${p.product.logo}`} />
                  <div className={style.details}>
                    <span className={style.name}>
                      {p.product.name}
                    </span>
                    {p.product.minimumReceiveAmount === p.product.maximumReceiveAmount &&
                      <React.Fragment>
                        {p.receiveCurrency.iso !== '000' &&
                          <span className={style.amount}>
                            <CurrencyFormat
                              value={p.product.minimumReceiveAmount}
                              currencyIso={p.receiveCurrency.iso} />
                          </span>
                        }
                        <span className={style.cost}>
                          <CurrencyFormat
                            value={round(p.product.minimumSendAmount / p.sendCurrency.rate)}
                            currencyIso={'₡CU'} />
                        </span>
                      </React.Fragment>
                    }
                    {p.product.minimumReceiveAmount !== p.product.maximumReceiveAmount &&
                      <React.Fragment>
                        <span className={style.amount}>
                          <CurrencyFormat
                            value={p.product.minimumReceiveAmount}
                            currencyIso={p.receiveCurrency.iso} />
                          -
                          <CurrencyFormat
                            value={p.product.maximumReceiveAmount}
                            currencyIso={p.receiveCurrency.iso} />
                        </span>
                        <span className={style.cost}>
                          <CurrencyFormat
                            value={round(p.product.minimumSendAmount / p.sendCurrency.rate)}
                            currencyIso={'₡CU'} />
                          -
                          <CurrencyFormat
                            value={round(p.product.maximumSendAmount / p.sendCurrency.rate)}
                            currencyIso={'₡CU'} />
                        </span>
                      </React.Fragment>
                    }
                    <span className={style.description}>
                      {getDescription(p)}
                    </span>
                    {p.countries.length < 10 &&
                      <div className={style.countriesContainer}>
                        {p.countries.map(c => (
                          <React.Fragment key={c.id}>
                            {c.name}{p.countries.indexOf(c) === p.countries.length - 1 ? '' : ', '}
                          </React.Fragment>
                        ))}
                      </div>
                    }
                    {p.countries.length >= 10 &&
                      <div className={style.countriesContainer}>
                        {'+10 countries'}
                      </div>
                    }
                  </div>
                </div>
              ))}
            </div>
          )
          let stores = this.game.stores.filter(s => s.productIds.length > 0)
          
          stores = stores.map(s => ({
            value: s.id,
            label: s.name
          }))
          
          let selectedStores = []
          if(this.state.selectedStores.length === 0 && stores.length > 0){
            selectedStores = [stores[0]]
          }else{
            selectedStores = this.state.selectedStores
          }

          const getStoreProducts = (filterCountry) => {
            // console.log(selectedStores)
            const stores = this.game.stores.filter(s => {
              const storeIds = selectedStores.map(st => st.value)
              return storeIds.includes(s.id)
            })
            if (stores.length === 0) {
              return []
            }
            let productIds = []
            for (const store of stores) {
              productIds = [...productIds, ...store.productIds]
            }
            const filteredByStore = this.state.storeProducts.filter(s => productIds.includes(s.product.reference))
            if (filterCountry) {
              return filteredByStore.filter(p => {
                const countryIds = p.countries.map(c => c.id)
                return countryIds.includes(filterCountry.value)
              })
            }
            return filteredByStore
          }

          let countries = {}
          for (const p of getStoreProducts()) {
            for (const c of p.countries) {
              countries[c.id] = c
            }
          }
          countries = Object.values(countries)
          countries = countries.map(c => ({
            value: c.id,
            label: c.name,
            iso: c.iso2
          }))

          let selectedCountry = null
          if (this.state.selectedCountry) {
            if (countries.map(c => c.value).includes(this.state.selectedCountry.value)) {
              selectedCountry = this.state.selectedCountry
            }
          } else if (this.state.geoip) {
            selectedCountry = countries.find(c => c.iso === this.state.geoip)
          }
          return (
            <div className={style.wrapper}>
              <ProductSelectionModal
                open={this.state.shouldShowProductModal}
                product={this.state.selectedProduct}
                onClose={() => { this.setState({ shouldShowProductModal: false, selectedProduct: null }) }}
                onSubmit={() => {
                  this.props.history.push('/checkout')
                }}
              />
              {this.state.loading &&
                <div className={style.spinner} >
                  <Spinner name='ball-scale-multiple' fadeIn='quarter' />
                </div>
              }
              {this.state.storeProducts.length === 0 && this.state.gameProducts.length === 0 && !this.state.loading &&
                <React.Fragment>
                  <h2>{t('GAME_PRODUCTS')}</h2>
                  <div className={style.productsContainer}>
                    <div className={style.noResults}>
                      <img src={noResultsImg} />
                      <span>{t('NO_RESULTS')}</span>
                    </div>
                  </div>
                </React.Fragment>
              }
              {this.state.gameProducts.length > 0 &&
                <React.Fragment>
                  <h2>{t('GAME_PRODUCTS')}</h2>
                  {getProducts(this.state.gameProducts)}
                </React.Fragment>
              }
              {this.state.storeProducts.length > 0 &&
                <React.Fragment>
                  <h2>{t('STORE_PRODUCTS')}</h2>
                  <div className={style.filters}>
                    <div className={style.filter}>
                      <Select
                        isMulti
                        value={selectedStores}
                        onChange={this.selectStore}
                        options={stores}
                        theme={theme => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#F5886C',
                            primary25: '#F5886C'
                          }
                        })}
                      />
                    </div>
                    <div className={style.filter}>
                      <Select
                        value={selectedCountry}
                        placeholder={t('SELECT_COUNTRY')}
                        onChange={this.selectCountry}
                        options={countries}
                        theme={theme => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#F5886C',
                            primary25: '#F5886C'
                          }
                        })}
                      />
                    </div>
                  </div>
                  {getProducts(getStoreProducts(selectedCountry))}
                </React.Fragment>
              }
            </div>
          )
        }
        }
      </NamespacesConsumer >
    )
  }
}
export default connect(state => ({
  countries: state.countries
}))(withRouter(withApi(Products)))
