import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import style from './Game.scss'
import excellentImg from '/../resources/img/ratings/excellent.png'
import recommendedImg from '/../resources/img/ratings/recommended.png'
import mehImg from '/../resources/img/ratings/meh.png'
import skipImg from '/../resources/img/ratings/skip.png'
import { getImageUrl } from '/services/Functions'

export default class Game extends React.Component {
  constructor ({ data, onClick }) {
    super()
    this.data = data
    this.onClick = () => {
      onClick(data)
    }
  }

  render () {
    let platformIconUrls = {}
    this.data.platforms.forEach(p => {
      platformIconUrls[p.icon] = true
    })
    platformIconUrls = Object.keys(platformIconUrls)
    return (
      <NamespacesConsumer ns={['game', 'genres']}>
        {t => {
          const getRating = () => {
            let text
            let img
            if (this.data.rating >= 4) {
              text = t('RATING_EXCELLENT')
              img = excellentImg
            } else if (this.data.rating >= 3) {
              text = t('RATING_RECOMMENDED')
              img = recommendedImg
            } else if (this.data.rating >= 2) {
              text = t('RATING_MEH')
              img = mehImg
            } else if (this.data.rating > 0) {
              text = t('RATING_SKIP')
              img = skipImg
            } else {
              text = t('RATING_UNKNOWN')
            }
            return (
              <React.Fragment>
                {text}
                {img &&
                  <img className={style.rating} src={img} />
                }
              </React.Fragment>
            )
          }
          const getGenres = () => {
            return this.data.genres.map(g => t('genres:' + g.name)).join(', ')
          }
          const getReleaseDate = () => {
            if (this.data.released) {
              return new Date(this.data.released).toLocaleDateString()
            } else {
              return t('TBA')
            }
          }
          return (
            <div className={style.wrapper} onClick={this.onClick}>
              <img
                onError={(e) => {
                  let newSource = ''
                  if (this.data.images.length > 0) {
                    newSource = `${process.env.REVOLUEGAME_ASSETS}${getImageUrl(this.data.images[0])}`
                  } else if (this.data.genres.length > 0) {
                    newSource = `${process.env.REVOLUEGAME_ASSETS}${getImageUrl(this.data.genres[0].image)}`
                  } else if (this.data.platforms.length > 0) {
                    newSource = `${process.env.REVOLUEGAME_ASSETS}${getImageUrl(this.data.platforms[0].image)}`
                  }
                  if (newSource !== e.target.src && newSource !== '') {
                    e.target.src = newSource
                  }
                }}
                src={`${process.env.REVOLUEGAME_ASSETS}${getImageUrl(this.data.mainImage)}`}
              />
              <div className={style.details}>
                <span>{this.data.name}</span>
                <div className={style.platforms}>
                  {platformIconUrls.map(p => (
                    <img
                      className={style.platform} key={p}
                      src={`${process.env.REVOLUEGAME_ASSETS}${p}`} />
                  ))}
                </div>
                <div className={style.data}>
                  <div className={style.dataPair}>
                    <span className={style.left}>{t('RATING')}:</span>
                    <span className={style.right}>{getRating()}</span>
                  </div>
                  <div className={style.dataPair}>
                    <span className={style.left}>{t('GENRES')}:</span>
                    <span className={style.right}>{getGenres()}</span>
                  </div>
                  <div className={style.dataPair}>
                    <span className={style.left}>{t('RELEASED')}:</span>
                    <span className={style.right}>{getReleaseDate()}</span>
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </NamespacesConsumer>
    )
  }
}
