import React from 'react'
import { NamespacesConsumer, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import style from './Style.scss'

export default () => (
  <NamespacesConsumer ns={['termsLegal']}>
    {t => {
      return (
        <div className={style.wrapper}>
          <section>
            <h1>{t('Terms and conditions')}</h1>
            <p>
              <Trans i18nKey='content-01-01'>
                RevoluPay S.L. (hereinafter RevoluPay) is the owner of the RevoluPay portal or the website to which you have accessed.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-01-02'>
                Access to the portal or any of the RevoluPay websites to information regarding any of the products and services contained therein implies acceptance of the general conditions provided in this Legal Notice. Therefore, you should carefully read its contents if you wish to access and make use of the information and services offered on the portal or on any of the RevoluPay websites.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('Your Instructions')}</h1>
            <p>
              <Trans i18nKey='content-13-01'>
              You shall ensure that all instructions provided to Revolupay through the Applications are accurate, complete and true. In particular, the mobile phone number to which any Top-up is to be credited must be correctly identified. You shall ensure that the instructions which are relayed back for confirmation are correct, accurate and true. All confirmed instructions are final and binding upon you. Revolupay and/or its service providers shall bear no liability or consequences related to the provision of incorrect, inaccurate or false information by you. You are solely responsible for any consequences related to the failure to provide correct, accurate and true information or the failure to correct such inaccurate information prior to final confirmation.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('Promotions')}</h1>
            <p>
              <Trans i18nKey='content-14-01'>
              From time to time, Revolupay or partner mobile operators will run promotions on the Applications. These promotions will be subject to additional terms and conditions and such terms and conditions (including the closing date for any promotion) will be displayed clearly on the Applications. Revolupay is not responsible for promotions run by partner mobile operators and you must make your own inquiries with the relevant mobile operator directly before availing of, and relying on the terms and conditions of, any such promotion.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('Validity of information')}</h1>
            <p>
              <Trans i18nKey='content-02-01'>
                The information contained in these pages is current as of the date of the last update.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-02-02'>
                These conditions are valid from the date of its last update. RevoluPay reserves the right to modify them at any time, in which case they will become effective as of their publication and will be applicable to all users of the portal or websites from that date.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-02-03'>
                The contents of any of the web pages, especially informative and advertising references, unless expressly stated otherwise, do not constitute a binding offer. RevoluPay reserves the right to introduce modifications or partially or totally omit the current contents when it deems appropriate, as well as to prevent or restrict access temporarily or permanently.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-02-04'>
                RevoluPay may include in any of its websites third-party content and links to third-party websites, always with the authorization of its owners, such as social networks and other information. In none of these cases will RevoluPay be responsible for the pages and contents of third parties, as well as their operation and availability.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('Navigation and customization')}</h1>
            <p>
              <Trans i18nKey='content-03-01'>
                Simple navigation through RevoluPay websites is free and does not require prior user registration. However, the access, contracting or use of some products or services may require the user to register. In this case, each of said products and services will be governed by its own specific conditions, without prejudice to the conditions set forth herein.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-03-02'>
                In some of the portals or websites the user will be offered the possibility of being able to personalize the pages, including different screen backgrounds or that can configure the accesses to their favorite products and services, making the navigation and the location of the resources used be, efficient, functional and therefore it adapts to the tastes and uses of each user.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('Security')}</h1>
            <p>
              <Trans i18nKey='content-04-01'>
                RevoluPay's website and websites are provided with the certificates, stamps or security and quality accreditations necessary to offer a safe environment to users. However, we recommend that you consult and carefully read the Security Recommendations that RevoluPay places on its website at the disposal of all users.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('Privacy policy and data protection.')}</h1>
            <p>
              <Trans i18nKey='content-05-01'>
                RevoluPay fully complies with current legislation on the protection of personal data and confidentiality commitments specific to the activity carried out.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-05-02'>
                RevoluPay has adopted the necessary technical measures to maintain the level of security required, according to the nature of the personal data processed and the circumstances of the treatment, in order to avoid, as far as possible and always according to the state of the art, its alteration, loss, treatment or unauthorized access.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-05-03'>
                When it is requested the completion of a form in which personal data are collected, the client or user of the recipient of the information will be informed, the purpose for which the data is collected, the identity and address of the person responsible for the file and the user's right to exercise the rights of access, rectification, cancellation and opposition to the processing of their data. The personal data collected will only be treated and / or assigned with the expressed purpose, and always with the consent of the user or client.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-05-03'>
                <p>
                  <Trans i18nKey='content-05-03'>
                    So that the information contained in our files is always up-to-date and does not contain errors, we ask our clients and users to inform us, as soon as possible, of the modifications and rectifications of their personal data.
                  </Trans>
                </p>
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('Cookies policy')}</h1>
            <p>
              <Trans i18nKey='content-06-01'>
              The web pages of RevoluPay have cookies, which are small data files that are stored in the user's or client's computer and which may allow our systems to remember characteristics or preferences of the navigation in our web pages that can be used to personalize their access in subsequent visits, make navigation safer, gather statistical information about the navigation made, or know user preferences.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-06-02'>
                RevoluPay's cookie policy is subject to current Community and Spanish regulations, regarding the processing of personal data and the protection of privacy in the electronic communications sector. By virtue thereof, RevoluPay will inform you of the cookies used in each of its websites ({(<Link to='/cookies'>Cookies policy</Link>)}) and, when necessary, request your consent to use them.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('Mobile portals and geolocation')}</h1>
            <p>
              <Trans i18nKey='content-07-01'>
              Some of the web pages of RevoluPay are adapted for use on mobile devices, so it is possible that the presentation and content of the different mobile applications do not match or are accurate to those of the portal or of the different websites.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-07-02'>
              Likewise, some of the web pages adapted for mobile devices or applications may have geolocation functions, in order to determine when the user activates it, the location of said device at all times. So in this way you can access services that require geolocation and that RevoluPay has available.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-07-03'>
              Access to the aforementioned services will require activation by the user of the geolocation function on your mobile device, which you can deactivate at any time.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('Intellectual and industrial property')}</h1>
            <p>
              <Trans i18nKey='content-08-01'>
              The portal and the web pages of RevoluPay, the pages it comprises and the information or elements contained therein, include texts, documents, photographs, drawings, graphic representations, databases, computer programs, as well as logos, trademarks, trade names. , or other distinctive signs, protected by intellectual or industrial property rights, of which RevoluPay or the companies of its Group are licensees or legitimate.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-08-02'>
              RevoluPay does not grant any guarantee regarding the legality and legality of the information or elements contained in the web pages of RevoluPay in the event that the ownership of the same does not correspond to RevoluPay or to the companies of its Group.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('Prohibited and permitted uses')}</h1>
            <p>
              <Trans i18nKey='content-09-01'>
              Any type of exploitation, including any type of reproduction, distribution, assignment to third parties, public communication and transformation, through any type of medium and media of the aforementioned works, creations and distinctive signs without prior and express authorization of their respective owners is prohibited. . Failure to comply with this prohibition may constitute an offense punishable by current legislation.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-09-02'>
              However, at your own risk, the user may download or make copies of such elements exclusively for personal use, provided that it does not infringe any of the intellectual or industrial property rights of RevoluPay. In particular, you can not alter, modify or delete them totally or partially. In no case shall this mean an authorization or license on the property rights of RevoluPay or the companies of its Group.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-09-03'>
              It is forbidden, except in the cases in which it is expressly authorized by RevoluPay, to establish links, hyperlinks or links, from portals or websites of third parties to RevoluPay web pages different from the main page of its portal, as well as to present the web pages of RevoluPay or the information contained in them under frames or frames, distinctive signs, trademarks or social or commercial denominations of another person, company or entity.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('Financial and stock information')}</h1>
            <p>
              <Trans i18nKey='content-10-01'>
              The RevoluPay website or websites do not provide any type of investment recommendation, legal advice, tax advice or any other kind of advice. The information is of general scope and in no case takes into account criteria or specific or particular objectives of investment or financial situation. Therefore, the information contained should not be used to make investments or make decisions.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-10-02'>
              In the case of negotiated securities, any subscription, purchase or sale decision must be adopted taking into account public information.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-10-03'>
              The contents of the web pages do not constitute an offer to sell shares in the United States of America. The shares can not be offered, sold or subscribed in the United States of America in the absence of registration thereof or of a registration exemption established in the Securities Market Act of the United States of America ("US Securities Act of 1933 ").
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('Responsibilities')}</h1>
            <p>
              <Trans i18nKey='content-11-01'>
              RevoluPay does not guarantee continued access, or the correct viewing, downloading or use of the elements and information contained in RevoluPay's web pages, which may be impeded, hindered or interrupted by factors or circumstances beyond its control.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-11-02'>
              RevoluPay is not responsible for the information and other content integrated in spaces or web pages of third parties accessible from the web pages of RevoluPay through links, hyperlinks or links, or information and other content integrated into spaces or web pages of third parties from which Access through links, hyperlinks or links to the RevoluPay portal or any of its web pages, or the information and content of any third-party website that appears under the appearance or distinctive signs of RevoluPay, unless expressly authorized by the latter.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-11-03'>
              RevoluPay and its information providers as third parties do not assume any responsibility in relation to the information, contents of all kinds, products and services offered or provided through the web pages of RevoluPay by third persons or entities, even if they belong to the same Group economic, and, especially, for damages of any kind that, linked to the foregoing, may be caused by: (i) absence or deficiencies in the information provided to the users or in their veracity, accuracy and sufficiency; (ii) non-compliance or defective or unpunctual fulfillment of the contracts or pre-contractual relationships; (iii) breach of the obligations incumbent upon the service providers of the information society; (iv) infringement of the rights of consumers and users; (v) infringement of intellectual and industrial property rights; carrying out acts of unfair competition or illegal advertising; (vi) infraction of the data protection right; of professional secrecy and the rights to honor, personal and family privacy and the image of people; (vii) in general, the breach of any laws, customs or codes of conduct that result from application and (viii) any decision made depending on the information provided through the RevoluPay portal.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-11-04'>
              Neither RevoluPay nor the information providers as third parties assume any responsibility for damages, losses, claims, or expenses, caused by: (i) interferences, interruptions, failures, omissions, telephone breakdowns, delays, blockages or disconnections in the operation of the electronic system, motivated by deficiencies, overloads and errors in the lines and telecommunications networks, or by any other cause beyond the control of RevoluPay ni; (ii) illegitimate intromissions through the use of malicious programs of any kind and through any means of communication, such as computer viruses or any other; (iii) improper or inappropriate use of the Revolupay web pages or; (iv) security or navigation errors caused by a malfunction of the browser or by the use of non-updated versions thereof.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('Applicable legislation')}</h1>
            <p>
              <Trans i18nKey='content-12-01'>
              These general conditions will be governed by Spanish legislation.
              </Trans>
            </p>
          </section>
        </div>

      )
    }}
  </NamespacesConsumer>
)
