export const FETCH = '@revoluegame/FETCH_STORES'
export const PUT_STORES = '@revoluegame/PUT_STORES'
export default ({
  fetch: () => ({
    type: FETCH
  }),
  put: (stores) => ({
    type: PUT_STORES,
    stores
  })
})
