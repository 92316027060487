import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import style from './Searchbar.scss'
import classNames from 'classnames'

export default class Searchbar extends React.Component {
  constructor ({ currentName }) {
    super()
    this.submit = this.submit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      name: currentName || ''
    }
  }

  handleChange (e) {
    this.setState({ name: e.target.value })
  }

  static getDerivedStateFromProps (props, state) {
    if (state.prevProps && state.prevProps.currentName !== props.currentName) {
      state.name = props.currentName || ''
    }
    state.prevProps = props
    return state
  }

  submit () {
    this.props.action({ name: this.state.name })
  }

  render () {
    const className = classNames(
      style.searchbox,
      { [style.disabled]: this.props.disabled }
    )
    return (
      <NamespacesConsumer ns={['home']}>
        {t => (
          <div className={className}>
            <div className={style.inputs}>
              <input
                value={this.state.name}
                name='name'
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.submit()
                  }
                }}
                onChange={this.handleChange}
                placeholder={t('SEARCH_GAME_PLACEHOLDER')}
                type='text'
                disabled={this.props.disabled} />
            </div>
            <button
              disabled={this.props.disabled}
              className={classNames(style.button, { [style.disabled]: this.props.disabled })}
              onClick={this.submit}
            >
              {t('GO')}
            </button>
          </div>
        )}
      </NamespacesConsumer>
    )
  }
}
