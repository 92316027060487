import { call, put, takeLatest } from 'redux-saga/effects'
import genresActions, { FETCH } from 'actions/genres'

export default ({ api }) => {
  function * getGenres () {
    try {
      const genres = yield call(api.getGenres)
      yield put(genresActions.put(genres))
    } catch (error) {
      console.error(error)
      yield put(genresActions.put([]))
    }
  }

  return function * watch () {
    yield takeLatest(FETCH, getGenres)
  }
}
