export const FETCH = '@revoluegame/FETCH_GENRES'
export const PUT_GENRES = '@revoluegame/PUT_GENRES'
export default ({
  fetch: () => ({
    type: FETCH
  }),
  put: (genres) => ({
    type: PUT_GENRES,
    genres
  })
})
