import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import style from './Games.scss'
import Searchbar from '../shared/Searchbar'
import queryString from 'query-string'
import noResultsImg from '/../resources/img/not-found.svg'
import Spinner from 'react-spinkit'
import Select from 'react-select'
import { withApi } from 'services/ApiContext'

import Game from '../shared/Game'

class Games extends React.Component {
  constructor({ api, history }) {
    super()
    this.api = api
    this.history = history
    this.state = {
      loading: true,
      count: 0,
      request: {
        offset: 0,
        sort: '-popularity',
        filters: {
          released:'2021-01-01,2023-01-01'
        }
      },
      games: []
    }
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search)
    const request = {}
    request.filters = {}
    if (query.name) {
      request.filters.name = decodeURIComponent(query.name)
    }
    if (query.genre) {
      request.filters.genre = query.genre
    }
    if (query.products) {
      request.filters.products = query.products
    }
    if (query.store) {
      request.filters.store = query.store
    }
    if (query.sort) {
      request.sort = query.sort
    }
    if (query.released) {
      request.filters.released = decodeURIComponent(query.released)
    }
    if (query.page) {
      request.offset = (parseInt(query.page) - 1) * 24
    }
    this.fetchGames(request, true)
  }

  fetchGames = (newRequest, isInitial = false) => {
    const newState = { loading: true }
    const request = Object.assign({}, this.state.request)
    const oldFilters = Object.assign({}, request.filters)
    Object.assign(request, newRequest)
    if (newRequest.filters) {
      if (!isInitial) {
        request.offset = 0
      }
      request.filters = Object.assign(oldFilters, newRequest.filters)
      if (newRequest.filters.name) {
        request.filters = {
          name: newRequest.filters.name
        }
      }
      if (
        newRequest.filters.genre ||
        newRequest.filters.released ||
        newRequest.filters.stores ||
        newRequest.filters.platform ||
        newRequest.filters.products
      ) {
        delete request.filters.name
      }
    }
    newState.request = request
    this.setState(newState)
    const attributes = {
      page: request.offset / 24 + 1,
      name: request.filters.name,
      genre: request.filters.genre,
      products: request.filters.products,
      stores: request.filters.stores,
      released: request.filters.released,
      sort: request.sort
    }
    const currentUrl = window.location.protocol + '//' + window.location.host + window.location.pathname
    const newUrl = encodeURI(`${currentUrl}?${queryString.stringify(attributes)}`)
    window.history.pushState({ path: newUrl }, '', newUrl)
    request.limit = 24
    this.api.getGames(request).then(data => {
      this.setState({ loading: false, games: data.games.elements, count: data.games.count })
      window.scrollTo(0, 0)
    }).catch(e => {
      console.log(e)
    })
  }

  nextPage = () => {
    this.fetchGames({ offset: this.state.request.offset + 24 })
  }

  previousPage = () => {
    this.fetchGames({ offset: this.state.request.offset - 24 })
  }

  selectGame = game => {
    this.history.push(`/game/${game.id}`)
  }

  selectGenre = selectedGenre => {
    let genre
    if (selectedGenre.value) {
      genre = selectedGenre.value
    }
    this.fetchGames({ filters: { genre } })
  }

  selectProducts = selectedProducts => {
    let products
    if (selectedProducts.value) {
      products = selectedProducts.value
    }
    this.fetchGames({ filters: { products } })
  }

  selectStores = (selectedStores, action) => {
    let stores
    if (action.action === 'select-option' && action.option.value === null) {
      stores = ''
    } else if (selectedStores && selectedStores.length > 0) {
      stores = selectedStores.map(s => s.value).join(',')
    }
    this.fetchGames({ filters: { stores } })
  }

  selectReleased = selectedReleased => {
    let released
    if (selectedReleased.value) {
      released = selectedReleased.value
    }
    this.fetchGames({ filters: { released } })
  }

  selectOrderBy = selectedOrderBy => {
    this.fetchGames({ sort: selectedOrderBy.value })
  }

  render() {
    return (
      <NamespacesConsumer ns={['game', 'genres']}>
        {t => {
          let genres = this.props.genres.data.map(g => ({
            value: g.id,
            label: t(`genres:${g.name}`)
          }))
          genres = [{ value: null, label: t('NO_GENRE') }, ...genres]
          let selectedGenre
          if (this.state.request.filters.genre) {
            selectedGenre = genres.find(g => g.value === this.state.request.filters.genre)
          }
          if (!selectedGenre) {
            selectedGenre = {
              value: null,
              label: t('NO_GENRE')
            }
          }

          let products = [
            {
              value: null,
              label: t('ALL_GAMES')
            },
            {
              value: 'games',
              label: t('GAMES_WITH_PRODUCTS')
            }
          ]
          let selectedProducts
          if (this.state.request.filters.products) {
            selectedProducts = products.find(p => p.value === this.state.request.filters.products)
          }
          if (!selectedProducts) {
            selectedProducts = {
              value: null,
              label: t('ALL_GAMES')
            }
          }

          let stores = this.props.stores.data.map(s => ({
            value: s.id,
            label: s.name
          }))
          stores = [{ value: null, label: t('NO_STORE') }, ...stores]
          let selectedStores = []
          if (this.state.request.filters.stores) {
            let storesFilterArray = this.state.request.filters.stores.split(',')
            console.log(storesFilterArray)
            selectedStores = stores.filter(s => storesFilterArray.includes(s.value))
          }

          if (!selectedStores || selectedStores.length === 0) {
            selectedStores = [{
              value: null,
              label: t('NO_STORE')
            }]
          }

          const released = [
            {
              value: null,
              label: t('NO_RELEASED')
            },
            {
              value: '2021-01-01,2023-01-01',
              label: '2021-2022'
            },
            {
              value: '2020-01-01,2022-01-01',
              label: '2020-2021'
            },
            {
              value: '2015-01-01,2020-01-01',
              label: '2015-2019'
            },
            {
              value: '2010-01-01,2016-01-01',
              label: '2010-2015'
            },
            {
              value: '2000-01-01,2011-01-01',
              label: '2000-2010'
            },
            {
              value: '1990-01-01,2001-01-01',
              label: '1990-2000'
            },
            {
              value: '1980-01-01,1991-01-01',
              label: '1980-1990'
            }
          ]
          let selectedReleased
          if (this.state.request.filters.released) {
            selectedReleased = released.find(r => r.value === this.state.request.filters.released)
          }
          if (!selectedReleased) {
            selectedReleased = {
              value: null,
              label: t('NO_RELEASED')
            }
          }
          const hasSelectedStores = () => {
            return selectedStores.filter(s => s.value !== null).length > 0
          }

          const buildTitle = () => {
            if (this.state.request.filters.name) {
              return t('TITLE_NAME_FILTER', { name: this.state.request.filters.name })
            } else {
              if (selectedReleased.value && selectedGenre.value && hasSelectedStores()) {
                return t('TITLE_ALL_FILTERS', {
                  released: selectedReleased.label,
                  genre: selectedGenre.label,
                  store: selectedStores.map(s => s.label).join(',')
                })
              } else if (hasSelectedStores() && selectedGenre.value) {
                return t('TITLE_FILTERS_STORE_GENRE', {
                  store: selectedStores.map(s => s.label).join(','),
                  genre: selectedGenre.label
                })
              } else if (hasSelectedStores() && selectedReleased.value) {
                return t('TITLE_FILTERS_STORE_RELEASED', {
                  store: selectedStores.map(s => s.label).join(','),
                  released: selectedReleased.label
                })
              } else if (selectedGenre.value && selectedReleased.value) {
                return t('TITLE_FILTERS_GENRE_RELEASED', {
                  genre: selectedGenre.label,
                  released: selectedReleased.label
                })
              } else if (selectedGenre.value) {
                return t('TITLE_FILTERS_GENRE', {
                  genre: selectedGenre.label
                })
              } else if (hasSelectedStores()) {
                return t('TITLE_FILTERS_STORE', {
                  store: selectedStores.map(s => s.label).join(',')
                })
              } else if (selectedReleased.value) {
                return t('TITLE_FILTERS_RELEASED', {
                  released: selectedReleased.label
                })
              } else {
                return t('TITLE_NO_FILTERS')
              }
            }
          }

          const orderBy = [
            {
              value: '-popularity',
              label: t('POPULARITY')
            },
            {
              value: '-rating',
              label: t('RATING')
            },
            {
              value: '-released',
              label: t('RELEASE')
            }
          ]
          const selectedOrderBy = orderBy.find(o => o.value === this.state.request.sort)

          return (
            <div className={style.wrapper}>
              <Searchbar
                currentName={this.state.request.filters.name}
                action={({ name }) => {
                  this.fetchGames({ filters: { name } })
                }}
              />
              <h1>{buildTitle()}</h1>
              <div className={style.filters}>
                <div className={style.filter}>
                  <Select
                    value={selectedOrderBy}
                    onChange={this.selectOrderBy}
                    options={orderBy}
                    styles={
                      {
                        singleValue: () => ({
                          ':before': {
                            content: `"${t('ORDER_BY')}:"`,
                            marginRight: 8,
                            fontWeight: 600
                          }
                        })
                      }
                    }
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#F5886C',
                        primary25: '#F5886C'
                      }
                    })}
                  />
                </div>
                {/* <div className={style.filter}>
                  <Select
                    value={selectedProducts}
                    onChange={this.selectProducts}
                    options={products}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#F5886C',
                        primary25: '#F5886C'
                      }
                    })}
                  />
                </div> */}
                <div className={style.filter}>
                  <Select
                    value={selectedGenre}
                    onChange={this.selectGenre}
                    options={genres}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#F5886C',
                        primary25: '#F5886C'
                      }
                    })}
                  />
                </div>
                <div className={style.filter}>
                  <Select
                    value={selectedStores}
                    isMulti
                    onChange={this.selectStores}
                    options={stores}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#F5886C',
                        primary25: '#F5886C'
                      }
                    })}
                  />
                </div>
                <div className={style.filter}>
                  <Select
                    value={selectedReleased}
                    onChange={this.selectReleased}
                    options={released}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#F5886C',
                        primary25: '#F5886C'
                      }
                    })}
                  />
                </div>
              </div>
              {this.state.loading && this.state.games.length > 0 &&
                <div className={style.spinner} >
                  <Spinner name='ball-scale-multiple' fadeIn='quarter' />
                </div>
              }
              <div className={style.games}>
                {this.state.games.map(g => (
                  <Game data={g} key={g.id} onClick={this.selectGame} />
                ))}
              </div>
              {this.state.loading &&
                <div className={style.spinner} >
                  <Spinner name='ball-scale-multiple' fadeIn='quarter' />
                </div>
              }
              {!this.state.loading && this.state.games.length > 0 &&
                <React.Fragment>
                  <div className={style.pageIndicator}>
                    <span>{t('PAGE', { page: this.state.request.offset / 24 + 1, of: Math.ceil(this.state.count / 24) })}</span>
                  </div>
                  <div className={style.pager}>
                    <button
                      disabled={this.state.request.offset < 24}
                      onClick={this.previousPage}>
                      {t('PREVIOUS')}
                    </button>
                    <button
                      disabled={this.state.count <= this.state.request.offset + 24}
                      onClick={this.nextPage}>
                      {t('NEXT')}
                    </button>
                  </div>

                </React.Fragment>
              }
              {this.state.games.length === 0 && !this.state.loading &&
                <div className={style.noResults}>
                  <img src={noResultsImg} />
                  <span>{t('NO_RESULTS')}</span>
                </div>
              }
            </div>
          )
        }
        }
      </NamespacesConsumer>
    )
  }
}
export default connect(state => ({
  genres: state.genres, stores: state.stores
}))(withRouter(withApi(Games)))
