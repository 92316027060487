import React from 'react'
import { NamespacesConsumer, withI18n } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import style from './Checkout.scss'
import CurrencyFormat from '../shared/CurrencyFormat'
import ProductSelectionModal from '../products/ProductSelectionModal'
import classNames from 'classnames'
import Spinner from 'react-spinkit'
import revoluvipLogoImg from '/../resources/img/revoluvip-logo.png'
import orderActions from 'actions/orders'
import revoluvipActions from 'actions/revoluvip'

class Checkout extends React.Component {
  state = {
    shouldShowProductModal: false,
    revoluvip: {
      email: '',
      password: ''
    },
    revoluVipFormErrors: {}
  }

  componentDidMount () {
    this.props.dispatch(revoluvipActions.clear())
  }

  hasRevoluvipFieldError = (fieldName) => {
    return this.state.revoluVipFormErrors[fieldName]
  }

  handleRevoluVipFormInput = (evt) => {
    const formErrors = this.state.revoluVipFormErrors
    formErrors[evt.target.name] = null
    const revoluvip = this.state.revoluvip
    revoluvip[evt.target.name] = evt.target.value
    this.setState({
      revoluVipFormErrors: formErrors,
      revoluvip
    })
  }
  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  applyRevoluvip = () => {
    const formErrors = {}
    const revoluvip = this.state.revoluvip
    if (!this.validateEmail(revoluvip.email)) {
      formErrors['email'] = true
    }
    if (revoluvip.password.length === 0) {
      formErrors['password'] = true
    }
    this.setState({ revoluVipFormErrors: formErrors, revoluvip })
    if (Object.keys(formErrors).length === 0) {
      this.props.dispatch(revoluvipActions.apply(this.state.revoluvip))
    }
  }

  placeOrder = () => {
    const shoppingCart = this.props.shoppingCart
    const request = {
      locale: this.props.i18n.language,
      receiveValue: shoppingCart.receiveValue,
      productId: shoppingCart.product.product.id,
      fields: shoppingCart.fields
    }
    if (this.props.revoluvip.reward) {
      request.revoluvip = this.state.revoluvip
    }
    this.props.dispatch(orderActions.place(request))
  }

  render = () => (
    <NamespacesConsumer ns={['checkout']}>
      {t => {
        if (!this.props.shoppingCart.product) {
          return (<Redirect to='/' />)
        }
        if (this.props.order.result) {
          return (<Redirect to='/payment' />)
        }
        const p = this.props.shoppingCart.product
        const fields = this.props.shoppingCart.fields
        const receiveValue = this.props.shoppingCart.receiveValue
        let sendValue = p.product.minimumSendAmount
        if (p.product.minimumReceiveAmount !== p.product.maximumReceiveAmount) {
          sendValue = (receiveValue * p.product.maximumSendAmount / p.product.maximumReceiveAmount) /
            p.sendCurrency.rate
        }
        return (
          <div className={style.wrapper}>
            <ProductSelectionModal
              open={this.state.shouldShowProductModal}
              product={p}
              receiveValue={receiveValue}
              fields={fields}
              onClose={() => { this.setState({ shouldShowProductModal: false }) }}
              onSubmit={() => { this.setState({ shouldShowProductModal: false }) }}
            />
            <h1>{t('TITLE')}</h1>
            <h2>{t('SUBTITLE')}</h2>
            <div className={style.productContainer}>
              <div className={style.links}>
                <a href='#' onClick={(e) => {
                  e.preventDefault()
                  this.props.history.goBack()
                }}>{t('BACK_LINK')}</a>
                <a href='#' onClick={(e) => {
                  e.preventDefault()
                  this.setState({ shouldShowProductModal: true })
                }}>{t('MODIFY')}</a>
              </div>
              <div className={style.logo}>
                <img src={`${process.env.REVOLUTILITY_ASSETS}/${p.product.logo}`} />
              </div>
              <div className={style.dataRow}>
                <span className={style.name}>{p.product.name}</span>
              </div>
              {p.product.description &&
                <div className={style.dataRow}>
                  <span className={style.label}>{t('PRODUCT_DETAILS')}</span>
                  <span className={style.value}>{p.product.description}</span>
                </div>
              }
              <div className={style.dataRow}>
                <div className={style.countriesWrapper}>
                  <span className={style.label}>{t('AVAILABLE_COUNTRIES')}</span>
                  <div className={style.container}>
                    {p.countries.map(c => (
                      <span
                        className={p.countries.length > 1 ? style.multiple : style.single}
                        key={c.id}>
                        {c.name}{p.countries.indexOf(c) === p.countries.length - 1 ? '' : ', '}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              {p.receiveCurrency.iso !== '000' &&
                <div className={style.dataRow}>
                  <span className={style.label}>{t('RECEIVE_VALUE')}</span>
                  <span className={style.value}>
                    <CurrencyFormat
                      value={receiveValue}
                      currencyIso={p.receiveCurrency.iso} />
                  </span>
                </div>
              }
              <div className={style.dataRow}>
                <span className={style.label}>{t('SEND_VALUE')}</span>
                <span className={style.value}>
                  <CurrencyFormat
                    value={sendValue}
                    currencyIso={'₡CU'} />
                </span>
              </div>
              <div className={style.dataRow}>
                <span className={style.label}>{t('REWARDS')}</span>
                <span className={style.value}>
                  <CurrencyFormat
                    value={(() => {
                      if (this.props.revoluvip.reward) {
                        return sendValue * this.props.revoluvip.reward / 100
                      } else {
                        return sendValue * this.props.config.data.revolupayReward / 100
                      }
                    })()}
                    currencyIso={'₡CU'} />
                </span>
              </div>
              {Object.keys(fields).length > 0 &&
                <span className={style.beneficiaryInfoLabel}>{t('BENEFICIARY_INFORMATION')}</span>
              }
              {Object.keys(fields).map(key => (
                <div key={key} className={style.dataRow}>
                  <span className={style.label}>{key}</span>
                  <span className={style.value}>{fields[key]}</span>
                </div>
              ))}
            </div>
            <div className={style.revoluvip}>
              <div className={style.revoluvipLogo}>
                <img src={revoluvipLogoImg} />
              </div>
              <span className={style.revoluvipDisclosure}>{t('REVOLUVIP_DISCLOSURE')}</span>
              <div className={style.form}>
                <div
                  className={classNames(
                    style.controlGroup, this.hasRevoluvipFieldError('email') ? style.error : null
                  )}>
                  <label htmlFor='email'>{t('EMAIL')}</label>
                  <input
                    type='text'
                    name='email'
                    id='revoluvipEmail'
                    disabled={this.props.revoluvip.reward > 0}
                    onChange={this.handleRevoluVipFormInput}
                    value={this.state.revoluvip.email}
                  />
                  {this.hasRevoluvipFieldError('email') &&
                    <span>{t('REVOLUVIP_EMAIL_ERROR')}</span>
                  }
                </div>
                <div
                  className={classNames(
                    style.controlGroup, this.hasRevoluvipFieldError('password') ? style.error : null
                  )}>
                  <label htmlFor='password'>{t('PASSWORD')}</label>
                  <input
                    type='password'
                    name='password'
                    id='revoluvipPassword'
                    disabled={this.props.revoluvip.reward > 0}
                    onChange={this.handleRevoluVipFormInput}
                    value={this.state.revoluvip.password}
                  />
                  {this.hasRevoluvipFieldError('password') &&
                    <span>{t('REVOLUVIP_PASSWORD_ERROR')}</span>
                  }
                </div>
                {this.props.revoluvip.error &&
                  <div className={style.revoluvipError}>
                    <span>{t('REVOLUVIP_APPLY_ERROR')}</span>
                  </div>
                }
                {this.props.revoluvip.loading &&
                  <div className={style.spinner}>
                    <Spinner name='ball-scale-multiple' fadeIn='none' />
                  </div>
                }
                <div className={style.button}>
                  <button
                    onClick={this.applyRevoluvip}
                    disabled={this.props.revoluvip.loading || this.props.revoluvip.reward > 0}
                  >
                    {this.props.revoluvip.reward > 0 ? t('REVOLUVIP_APPLIED') : t('APPLY_REVOLUVIP')}
                  </button>
                </div>
              </div>
            </div>
            <div className={style.proceed}>
              {this.props.order.loading &&
                <div className={style.spinner}>
                  <Spinner name='ball-scale-multiple' fadeIn='none' />
                </div>
              }
              {this.props.order.error &&
              <div className={style.orderError}>
                <span>{t('PLACE_ORDER_ERROR')}</span>
              </div>
              }
              <button onClick={this.placeOrder} disabled={this.props.order.loading} >
                {t('PLACE_ORDER')}
              </button>
            </div>
          </div>

        )
      }}
    </NamespacesConsumer>
  )
}

export default connect(state => ({
  shoppingCart: state.shoppingCart,
  config: state.config,
  revoluvip: state.revoluvip,
  order: state.order
}))(withI18n()(withRouter(Checkout)))
