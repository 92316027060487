import { call, put, takeLatest } from 'redux-saga/effects'
import storesActions, { FETCH } from 'actions/stores'

export default ({ api }) => {
  function * getStores () {
    try {
      const stores = yield call(api.getStores)
      yield put(storesActions.put(stores))
    } catch (error) {
      console.error(error)
      yield put(storesActions.put([]))
    }
  }

  return function * watch () {
    yield takeLatest(FETCH, getStores)
  }
}
