import { FETCH, PUT_STORES } from 'actions/stores'
export default (
  state = {
    loading: false,
    data: []
  },
  action
) => {
  switch (action.type) {
    case FETCH:
      return Object.assign({}, state, { loading: true })
    case PUT_STORES:
      return Object.assign({}, state, { loading: false, data: action.stores })
    default:
      return state
  }
}
