import { combineReducers } from 'redux'
import currenciesReducer from 'reducers/currenciesReducer'
import configReducer from 'reducers/configReducer'
import productsReducer from 'reducers/productsReducer'
import shoppingCartReducer from 'reducers/shoppingCartReducer'
import revoluvipReducer from 'reducers/revoluvipReducer'
import orderReducer from 'reducers/orderReducer'
import paymentReducer from 'reducers/paymentReducer'
import genresReducer from 'reducers/genresReducer'
import storesReducer from 'reducers/storesReducer'

export default combineReducers({
  currencies: currenciesReducer,
  config: configReducer,
  products: productsReducer,
  shoppingCart: shoppingCartReducer,
  revoluvip: revoluvipReducer,
  order: orderReducer,
  payment: paymentReducer,
  genres: genresReducer,
  stores: storesReducer
})
