import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import style from './Home.scss'
import Searchbar from '../shared/Searchbar'

import revoluchargeImg from '/../resources/img/home/revolucharge.png'
import revoloufinImg from '/../resources/img/home/revolufin.png'
import revolupayImg from '/../resources/img/home/revolupay.png'
import revolutilityImg from '/../resources/img/home/revolutility.png'
import revoluvipImg from '/../resources/img/home/revoluvip.jpg'

import revolupayLogoImg from '/../resources/img/home/revolupay-logo-light.png'
import revolupayLogoDark from '/../resources/img/home/revolupay-logo.png'
import appStoreImg from '/../resources/img/home/appstore.png'
import playStoreImg from '/../resources/img/home/playstore.png'

import Spinner from 'react-spinkit'
import { withApi } from 'services/ApiContext'
import Game from '../shared/Game'
import { getImageUrl } from '/services/Functions'

class Home extends React.Component {
  constructor({ api }) {
    super()
    this.api = api
    this.state = {
      loading: true,
      new: [],
      bestRated: []
    }
  }

  componentDidMount() {
    const date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth() - 3, 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 6, 0);
    let dates = `${firstDay.toISOString().slice(0, 10)},${lastDay.toISOString().slice(0, 10)}`;
    this.api.getGames({
      filters: {
        released: dates,
      },
      sort: '-popularity',
      limit: 8,
      offset: 0
    }).then(data => {
      this.setState({ loading: false, new: data.games.elements })
    }).catch(e => {
      console.log(e)
    })
    firstDay = new Date(date.getFullYear(), date.getMonth() - 12, 1);
    lastDay = new Date(date.getFullYear(), date.getMonth() + 12, 0);
    dates = `${firstDay.toISOString().slice(0, 10)},${lastDay.toISOString().slice(0, 10)}`;
    this.api.getGames({
      filters: {
        released: dates,
      },
      sort: '-popularity',
      limit: 8,
      offset: 0
    }).then(data => {
      this.setState({ bestRated: data.games.elements })
    }).catch(e => {
      console.log(e)
    })
  }

  componentWillUnmount() {
  }

  render() {
    const { history } = this.props
    return (
      <NamespacesConsumer ns={['home', 'genres']}>
        {t => (
          <div className={style.wrapper}>
            <div className={style.welcome}>
              <h1>{t('TITLE')}</h1>
              <h3>{t('SUBTITLE')}</h3>
              <Searchbar
                action={({ name }) => {
                  history.push(`/games?name=${name}`)
                }}
              />
              <div className={style.disclosure}>
                <div className={style.wrapper}>
                  <img src={revolupayLogoImg} />
                  <div className={style.downloadImgs}>
                    <a href='https://play.google.com/store/apps/details?id=com.revolupayclient.vsla.revolupayconsumer' target='_blank'><img src={playStoreImg} /></a>
                    <a href='https://itunes.apple.com/us/app/revolupay/id1437640699?mt=8&ign-mpt=uo%3D4' target='_blank'><img src={appStoreImg} /></a>
                  </div>
                  <div className={style.description}>
                    <span>{t('REWARDS', { reward: this.props.config.data.revolupayReward })} <a href='https://www.revoluvip.club'>{t('CLUB_REVOLUVIP')}</a></span>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.disclosure}>
              <div className={style.wrapper}>
                <img src={revolupayLogoDark} />
                <div className={style.downloadImgs}>
                  <a href='https://play.google.com/store/apps/details?id=com.revolupayclient.vsla.revolupayconsumer' target='_blank'><img src={playStoreImg} /></a>
                  <a href='https://itunes.apple.com/us/app/revolupay/id1437640699?mt=8&ign-mpt=uo%3D4' target='_blank'><img src={appStoreImg} /></a>
                </div>
                <div className={style.descriptionResponsive}>
                  <span>{t('REWARDS', { reward: this.props.config.data.revolupayReward })} <a href='https://www.revoluvip.club'>{t('CLUB_REVOLUVIP')}</a></span>
                </div>
              </div>
            </div>
            {this.state.loading === false &&
              <React.Fragment>
                <div className={style.disclaimer}>
                  <h1>{t('NEW_RELEASES')}</h1>
                  <div className={style.gamesContainer}>
                    {this.state.new.map(g => (
                      <Game data={g} key={g.id} onClick={() => {
                        history.push(`/game/${g.id}`)
                      }} />
                    ))}
                  </div>
                </div>
                <div className={style.disclaimer}>
                  <h1>{t('GENRES')}</h1>
                  <div className={style.genresContainer}>
                    {this.props.genres.data.map(g => (
                      <div
                        key={g.id}
                        onClick={() => {
                          history.push(`/games?genre=${g.id}`)
                        }}
                        className={style.genre}>
                        <div className={style.imgContainer}>
                          <img src={`${process.env.REVOLUEGAME_ASSETS}${getImageUrl(g.image)}`} />
                        </div>
                        <span>{t('genres:' + g.name)}</span>
                      </div>
                    ))}

                  </div>
                  <div className={style.button} onClick={() => {
                    history.push('/games')
                  }}>
                    {t('BROWSE_GAMES')}
                  </div>
                </div>

                <div className={style.disclaimer}>
                  <h1>{t('BEST_RATED')}</h1>
                  <div className={style.gamesContainer}>
                    {this.state.bestRated.map(g => (
                      <Game data={g} key={g.id} onClick={() => {
                        history.push(`/game/${g.id}`)
                      }} />
                    ))}
                  </div>
                </div>
              </React.Fragment>
            }
            {this.state.loading &&
              <div className={style.loading}>
                <span>{t('LOADING')}</span>
                <div className={style.spinner}>
                  <Spinner name='ball-scale-multiple' fadeIn='none' />
                </div>
              </div>
            }

            <div className={style.disclaimer}>
              <h1>{t('POWERED_BY')} <a href='https://rawg.io'>RAWG</a></h1>
              <p>{t('RAWG')}</p>
              <a href='https://rawg.io'>https://rawg.io</a>
            </div>
            <div className={style.references}>
              <h1>{t('SERVICES')}</h1>
              <div className={style.products}>
                <div className={style.product}>
                  <a href='https://www.revolucharge.com'>
                    <img src={revoluchargeImg} alt='' />
                    <div className={style.overlay} />
                    <span className={style.title}>RevoluCHARGE</span>
                    <span className={style.detail}>{t('REVOLUCHARGE')}</span>
                  </a>
                </div>
                <div className={style.product}>
                  <a href='https://www.revolufin.com' target='_blank'>
                    <img src={revoloufinImg} alt='' />
                    <div className={style.overlay} />
                    <span className={style.title}>RevoluFIN</span>
                    <span className={style.detail}>{t('REVOLUFIN')}</span>
                  </a>
                </div>
                <div className={style.product}>
                  <a href='https://www.revolupay.es' target='_blank'>
                    <img src={revolupayImg} alt='' />
                    <div className={style.overlay} />
                    <span className={style.title}>RevoluPAY</span>
                    <span className={style.detail}>{t('REVOLUPAY')}</span>
                  </a>
                </div>
                <div className={style.product}>
                  <a href='#' target='_blank'>
                    <img src={revolutilityImg} alt='' />
                    <div className={style.overlay} />
                    <span className={style.title}>RevolUTILITY</span>
                    <span className={style.detail}>{t('REVOLUTILITY')}</span>
                  </a>
                </div>
                <div className={style.product}>
                  <a href='http://www.revoluvip.club' target='_blank'>
                    <img src={revoluvipImg} alt='' />
                    <div className={style.overlay} />
                    <span className={style.title}>RevoluVIP</span>
                    <span className={style.detail}>{t('REVOLUVIP')}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </NamespacesConsumer>
    )
  }
}
export default connect(state => ({
  config: state.config, genres: state.genres
}))(withRouter(withApi(Home)))
